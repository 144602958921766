footer {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 64px * 2);
  height: auto;
  padding: 64px 64px 96px 64px;
  background-color: black;
}
footer img {
  width: 150px;
  height: auto;
  fill: white;
  margin-bottom: 16px;
}
footer p {
  font-size: 12px;
  color: white;
}

/*# sourceMappingURL=Footer.css.map */
