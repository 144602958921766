section#hero {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: calc(94vh - (64px * 2));
  width: calc(100vw - (64px * 2));
  padding: 64px;
  overflow: hidden;
  background-image: url("https://res.cloudinary.com/resource-network/image/upload/v1633637911/resource-cloud-city/hero_e92k8n.jpg"), url("https://res.cloudinary.com/resource-network/image/upload/v1633637911/resource-cloud-city/hero_ijt4mn.webp");
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: unset;
  background-position: left center;
}
@media screen and (max-width: 450px) {
  section#hero {
    padding: 24px;
    height: calc(94vh - (24px * 2));
    width: calc(100vw - (24px * 2));
  }
}
section#hero header {
  width: 60vw;
  max-width: 500px;
  min-width: 300px;
  overflow: visible;
  z-index: 0;
}
@media screen and (max-width: 1000px) {
  section#hero header {
    max-width: 400px;
  }
}
@media screen and (max-width: 450px) {
  section#hero header {
    max-width: calc(100vw - (24px * 2));
    width: calc(100vw - (24px * 2));
  }
}
section#hero header picture#cloud, section#hero header img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1078px;
  width: 1000px;
  z-index: -5;
}
@media screen and (max-width: 1000px) {
  section#hero header picture#cloud, section#hero header img {
    height: calc(1078px * 0.66);
    width: calc(1000px * 0.66);
  }
}
section#hero header h1 {
  color: black;
  font-size: 2.6em;
  font-weight: 600;
}
@media screen and (max-width: 1000px) {
  section#hero header h1 {
    font-size: 1.8em;
  }
}
@media screen and (max-width: 450px) {
  section#hero header h1 {
    font-size: 1.4em;
  }
}
section#hero header p {
  color: black;
  font-size: 1.2em;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  section#hero header p {
    font-size: 1em;
  }
}
@media screen and (max-width: 450px) {
  section#hero header p {
    font-size: 0.95em;
  }
}

/*# sourceMappingURL=Hero.css.map */
