section#form {
  position: fixed;
  display: flex;
  z-index: 99;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  flex-flow: column nowrap;
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 0, 0.67);
  border-radius: 32px;
  padding: 32px;
}
section#form.hide {
  display: none;
}
section#form div#form-header {
  display: flex;
  flex-flow: row nowrap;
  color: white;
  justify-content: space-between;
  align-items: flex-start;
}
section#form div#form-header a {
  font-weight: bold;
  cursor: pointer;
  padding: 0.5em 1em;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
}
section#form div#form-header a:hover {
  background-color: black;
}
section#form h4 {
  font-size: 1.6em;
  font-weight: bold;
  color: white;
  margin-bottom: 1em;
}
section#form form {
  display: flex;
  flex-flow: column nowrap;
}
section#form form input {
  outline: none;
  padding: 6px 12px;
  margin: 8px 0;
  min-width: 300px;
  border-radius: 16px;
  border-image: none;
  border-style: none;
}
section#form form input#submit {
  background-color: #957fef;
  cursor: pointer;
  color: white;
  font-weight: bold;
  height: 48px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.2em;
}
section#form form input#submit:hover {
  background-color: #7161EF;
}

/*# sourceMappingURL=Form.css.map */
