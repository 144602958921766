section#map {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 64px;
  width: calc(100vw - (64px * 2));
  height: auto;
}
section#map picture, section#map img {
  width: 100%;
  height: auto;
}

/*# sourceMappingURL=Map.css.map */
