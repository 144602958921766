section#city-details {
  display: flex;
  flex-flow: column nowrap;
  width: 100vw;
  height: auto;
}
section#city-details div.city-details-group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  width: calc(100vw - (64px * 2));
  height: auto;
  padding: 64px;
}
section#city-details div.city-details-group:last-child {
  flex-flow: row-reverse nowrap;
}
section#city-details div.city-details-group:last-child div.city-details-text-group {
  margin-right: unset;
  margin-left: 64px;
}
@media screen and (max-width: 800px) {
  section#city-details div.city-details-group {
    flex-flow: column nowrap;
  }
  section#city-details div.city-details-group div.city-details-text-group {
    margin-right: 0;
    margin-left: 0;
  }
  section#city-details div.city-details-group:last-child {
    flex-flow: column nowrap;
  }
  section#city-details div.city-details-group:last-child div.city-details-text-group {
    margin-right: 0;
    margin-left: 0;
  }
}
@media screen and (max-width: 450px) {
  section#city-details div.city-details-group {
    width: calc(100vw - (24px * 2));
    padding: 24px;
  }
}
section#city-details div.city-details-group div.city-details-text-group {
  margin-right: 64px;
}
@media screen and (max-width: 800px) {
  section#city-details div.city-details-group div.city-details-text-group {
    margin-right: 0;
    margin-bottom: 64px;
  }
}
@media screen and (max-width: 450px) {
  section#city-details div.city-details-group div.city-details-text-group {
    margin-bottom: 24px;
  }
}
section#city-details div.city-details-group div.city-details-text-group h2 {
  font-size: 1.8em;
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  section#city-details div.city-details-group div.city-details-text-group h2 {
    font-size: 1.2em;
  }
}
section#city-details div.city-details-group div.city-details-text-group p {
  font-size: 1.2em;
  font-weight: 400;
  margin: 8px 0;
}
@media screen and (max-width: 1200px) {
  section#city-details div.city-details-group div.city-details-text-group p {
    font-size: 1em;
  }
}
section#city-details div.city-details-group picture, section#city-details div.city-details-group img {
  position: relative;
  width: 50vw;
  margin-left: 0;
  border-radius: 24px;
}
@media screen and (max-width: 800px) {
  section#city-details div.city-details-group picture, section#city-details div.city-details-group img {
    width: calc(100vw - (64px * 2));
  }
}
@media screen and (max-width: 450px) {
  section#city-details div.city-details-group picture, section#city-details div.city-details-group img {
    width: calc(100vw - (24px * 2));
  }
}

/*# sourceMappingURL=CityDetails.css.map */
