nav#nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px 24px 24px 24px;
  width: calc(100vw - 48px);
  height: calc(72px - (24px*2));
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.33);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
@media screen and (max-width: 350px) {
  nav#nav {
    height: calc(64px - (24px*2));
  }
}

img#logo {
  height: calc(72px - (24px*2));
  margin-left: 8px;
}
@media screen and (max-width: 350px) {
  img#logo {
    height: calc(64px - (24px*2));
  }
}

div#nav-link-list {
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: auto;
}
div#nav-link-list a {
  margin: 8px;
  display: inline;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  font-size: 1.2em;
  height: fit-content;
  color: white;
  border-radius: 16px;
  font-family: "Righteous", "Space Mono", "Poppins", cursive;
  font-weight: 400;
  transition: 0.5s;
}
@media screen and (max-width: 600px) {
  div#nav-link-list a {
    margin: 4px;
  }
}
@media screen and (max-width: 350px) {
  div#nav-link-list a {
    font-size: 1em;
  }
}
div#nav-link-list a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/*# sourceMappingURL=Nav.css.map */
